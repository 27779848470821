<template>
  <div>
    <a-modal
      :visible="visible"
      title="编辑"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'name',
              {
                initialValue: item.name,
                rules: [
                  { required: true, message: '请输入名称' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="规则">
          <a-input
            v-decorator="['rules', { initialValue: item.rules, rules: [{ max: 9999, message: '超出最大长度(9999)' }] }]"
            :allowClear="true"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { packingCodeUpdate } from "@/apis/data";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          packingCodeUpdate({ id: this.item.id, ...values })
            .then((data) => {
              this.$emit("update", data);
              this.$message.success("编辑成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
